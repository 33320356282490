.main_new_layout{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -60px;
}
.main_first{
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.main_first img{
    margin-bottom: 30px;
}
@media screen and (max-width: 600px) {
    .main_first img{
        width: 80%;
    }
}

.main_about{
    width: 100%;
    margin-top: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main_about_title{
    color: var(--color-green);
    font-size: 46px;
    margin: 5px 0;
    font-weight: bold;
}
.main_about_pic_tall{
    width: 300px;
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
}
.main_about_rightside{
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 600px;
}
.main_about_pic_rightside{
    width: 380px;
    height: 280px;
    margin: 0 0 0 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
}
.main_about_text{
    max-width: 400px;
    height: 300px;
    font-size: 16px;
    padding: 24px;
    margin: 0 0 20px 20px;
    text-align: center;
    background-color: rgb(48, 48, 48);
    border-radius: 8px;
    color: white;
}
.font_color_green{
    color: var(--color-green);
}
@media screen and (max-width: 800px) {
    .main_about_pic_tall{
        display: none;
    }
    .main_about_rightside{
        height: auto;
    }
    .main_about_pic_rightside{
        display: none;
    }
    .main_about_text{
        height: auto;
        width: auto;
        margin: 20px;
        padding: 20px 50px;
    }
}


.main_people{
    width: 100%;
    padding: 40px 0;
    margin-bottom: 30px;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main_people_title{
    font-size: 36px;
    margin-bottom: 30px;
    cursor: pointer;
}
.main_people_title:hover{
    text-decoration: underline;
}
.main_people_slider{
    flex-wrap: nowrap;
    width: 100%;
    padding-right: 30px;
    align-items: center;
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}
.main_people_slider::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */

}
.main_people_touch_icon{
    display: none
}
.main_people_slide_icon{
    display: block;
    height: 20px;
    margin-left: 30px;
    margin-right: 10px;
}
.main_people_person{
    width: 200px;
    min-width: 200px;
    margin-left: 30px;
    cursor: pointer;
}
.main_person_photo{
    width: 200px;
    height: 300px;
    border-radius: 8px;
}
.main_person_name_first{
    color: rgb(240, 240, 240);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}
.main_person_name_last{
    color: rgb(159, 159, 159);
    font-size: 18px;
    text-align: center;
}
@media screen and (max-width: 450px) {
    .main_people_slide_icon{
        display: none;
    }
    .main_people_touch_icon{
        display: block;
        height: 40px;
        margin-left: 10px;
        margin-right: 0;
    }
    .main_people_person{
        width: 120px;
        min-width: 120px;
        margin-left: 20px;
    }
    .main_person_photo{
        width: 120px;
        height: 180px;
    }
    .main_person_name_first{
        font-size: 18px;
    }
    .main_person_name_last{
        font-size: 12px;
    }
}


.main_gallery_section{
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.main_gallery_section:hover{
    transition: opacity 0.3s;
    opacity: 0.5;
}
.main_gallery_section_button{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 36px;
    cursor: pointer;
}

.main_contact_area{
    width: 100%;
    margin-top: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.main_contact_area_inner{
    background-color: rgb(48, 48, 48);
    border-radius: 8px;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.main_contact_text{
    width: 100%;
    font-size: 20px;
    text-align: center;
    color: white;
    font-weight: bold;
}
.main_contact_button{
    text-align: center;
    width: 200px;
    font-size: 18px;
    margin-top: 20px;
    color: rgb(48, 48, 48);
    background-color: #60e328;
    font-weight: bold;
    border-radius: 3px;
    padding: 8px 16px;
    cursor: pointer;
}
.main_contact_button:hover{
    transition: color 0.2s;
    color: white;
}

.main_layout{
    height: 100%; 
    width: 100%;
    margin-top: -60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.full_height_section{
    height: 100%; 
    width: 100%;
}
.main_layout img{
    width: 500px;
    margin-bottom: 20px;
}
@media screen and (max-width: 600px) {
    .main_layout img{
        width: 80%;
    }
}

.mobile{
    display: none;
}
@media screen and (max-width: 450px) {
    .mobile{
        display: flex;
    }
}

.button{
    font-weight: bold;
    border-radius: 3px;
    padding: 10px 20px;
    /* background-color: rgb(250,250,250); */
    background-color: #60e328;
    cursor: pointer;
    color: black;
}
.button:hover{
    /* background-color: #60e328; */
    color: white;
    transition: color 0.2s;
}
.button span{
    pointer-events: none;
}
.main_image_section_1{
    width: 100%;

}

/*  shop section  */
.main_shop_area{
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.main_shop_title{
    font-size: 36px;
    margin-bottom: 30px;
}
.main_shop_item_area{
    width: 700px;
    display: flex;
    justify-content: space-around;
}
.main_shop_item{
    display: block;
    padding: 8px;
    width: 200px;
    display: flex;
    flex-direction: column;
    /* background-color: rgb(48, 48, 48); */
    background-color: white;
    color: black;
    border-radius: 8px;
    text-decoration: none;
}
.main_shop_item_image{
    width: 100%;
    border-radius: 8px;
}
.main_shop_item_info{
    padding: 6px 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.main_shop_item_name{
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 22px;
}
.main_shop_item_price{
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
}
.main_shop_item:hover .main_shop_item_name{
    text-decoration: underline;
}
@media screen and (max-width: 700px) {
    .main_shop_item_area{
        width: 100%;
    }
    .main_shop_item{
        width: 28%;
    }
}
@media screen and (max-width: 500px) {
    .main_shop_area{
        margin-bottom: 0;
    }
    .main_shop_item_area{
        flex-wrap: wrap;
    }
    .main_shop_item{
        width: 43%;
        margin-bottom: 20px;
    }
    .main_shop_item_name{
        font-size: 18px;
    }
    .main_shop_item_price{
        font-size: 12px;
    }
}