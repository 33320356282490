header{
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    z-index: 9;
    position: relative;
    transition: opacity .4s;
}
.shadow {
    box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.5);
    clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
}
header a {
    display: block;
    display: flex;
    align-items: center;
    font-size: 18px;
}
header img{
    height: 60px;
}
.menu_area{
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
}
.menu_area a{
    text-decoration: none;
    color: white;
    font-weight: bold;
    padding-left: 30px;
}
.menu_area a:hover{
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #60e328;
}
.menu_area a.active{
    text-decoration: underline;
    text-decoration-color: #60e328;
    text-decoration-thickness: 3px;
}
.header_button{
    font-weight: bold;
    border-radius: 3px;
    padding: 8px 16px;
    background-color: #60e328;
    cursor: pointer;
    color: black;
}
.header_button span{
    pointer-events: none;
}

.header_button:hover{
    transition: color 0.2s;
    color: white;
}
.header_button_hide{
    display: none;
}
.hamburger{
    display: none;
}

@media screen and (max-width: 450px) {
    header.non_homepage_only{
        position: relative;
    }
    header.homepage_only{
        position: fixed;
        top: 0;
        left: 0;
        transition: opacity .4s;
        opacity: 1;
    }
    header.homepage_only_hide{
        transition: opacity 0;
        opacity: 0;
    }
    .hamburger{
        display: flex;
    }
    .menu_area{
        display: none;
    }
    
}