* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html, #root {
  height: 100%;
  margin: 0;
  background-color: rgb(9, 9, 9);
}
