.layout{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.gallery_title{
    color: white;
    font-weight: bold;
    font-size: 38px;
    width: 100%;
    text-align: center;
    padding: 5px 0 20px 0;
}
.layout img{
    border-radius: 7px;
    padding: 5px;
}
.horizontal{
    width: 65%
}
.vertical{
    width: 35%;
}
@media screen and (max-width: 450px) {
    .gallery_title{
        font-size: 24px;
    }
    .horizontal{
        width: 100%
    }
    .vertical{
        width: 100%;
    }
  }