.footer_area{
    width: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    color: rgb(190,190,190);
    flex-wrap: wrap;
    background-color: rgb(9, 9, 9);
    /* align-items: center; */
}
.business_info{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.name{
    width: 100%;
    padding: 5px 0;
    display: flex;
    font-size: 24px;
    justify-content: center;
    color: rgb(96 227 41);
    text-align: center;
}
.address{
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: rgb(190,190,190);
    text-align: center;
}
.phone{
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: rgb(190,190,190);
    text-align: center;
}

.links{
    width: 60%;
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
}
a.footer{
    margin: 0 4px;
    color: rgb(190,190,190);
    text-decoration: none;
}
a.footer:hover{
    text-decoration: underline;
    text-decoration: underline;
    text-decoration-color: #60e328;
    text-decoration-thickness: 3px;
}

@media screen and (max-width: 600px) {
    .business_info{
        width: 100%;
    }
    .name{
        font-size: 18px;
    }
    .links{
        width: 90%;
    }
}