
.team_layout{
    /* height: 100%;  */
    width: 100%;
    margin-top: -60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
}
.team_title{
    color: white;
    font-weight: bold;
    font-size: 38px;
    width: 100%;
    text-align: center;
    padding: 5px 0 20px 0;
}
.team_members{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 80px;
    /* width: 1000px; */
}
.member{
    width: 400px;
    margin: 10px;
    background-color: black;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 30px;
}
.member_solo{
    margin-right: 30%;
    margin-left: 30%;
}
@media screen and (max-width: 600px) {
    .member{
        width: auto;
    }
    .team_title{
        font-size: 24px;
    }
    .member_solo{
        margin-bottom: 30px;
    }
    .team_members{
        padding: 20px;
    }
}
.member_photo_area{
    height: 200px;
}
.member_photo_area img{
    height: 100%;
    border-radius: 5px;
}
.member_info_area{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.member_name{
    margin-bottom: 10px;
}
.member_name_first{
    color: rgb(240, 240, 240);
    font-size: 24px;
    font-weight: bold;
}
.member_name_last{
    color: rgb(159, 159, 159);
    font-size: 18px;
    margin-left: 6px;
}
.member_bio{
    color: rgb(240, 240, 240);
    font-size: 12px;
}
.member_links{
    display: flex;
    justify-content: flex-start;
}
.member_links img{
    height: 20px;
    cursor: pointer;
}

a.social_link{

    margin-bottom: 10px;
    display: block;
    width: 20px; height: 20px;
    margin-right: 10px;
    text-decoration: none;
    cursor: pointer;
}