.contact_layout{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    color: white;
}
.contact_title{
    font-size: 34px;
    padding: 18px 0;
}
form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.input_row{
    display: flex;
    flex-direction: column;
    padding: 6px 12px;
}
.input_row_label{
    line-height: 34px;
}
input{
    width: 300px;
    padding: 6px 12px;
}
textarea{
    width: 300px;
    height: 200px;
    padding: 6px 12px;
}

button{
    width: 300px;
    font-size: 18px;
    margin-top: 40px;
    background-color: #60e328;
    border: none;
    font-weight: bold;
    border-radius: 3px;
    padding: 8px 16px;
    cursor: pointer;
}
button:hover{
    transition: color 0.2s;
    color: white;
}